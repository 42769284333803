<template>

  <div class="single-contractor">
    <b-row>
      <b-col>
        <b-tabs pills>
          <b-tab
            class="pt-1"
            title="Dane podstawowe pobrane z GUS"
            active
          >
            <b-card-text>

              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset.prevent="resetForm"
                >
                  <b-row>

                    <!-- NIP -->
                    <b-col
                      md="6"
                      xl="3"
                    >

                      <validation-provider
                        #default="validationContext"
                        name="NIP"
                        rules="required"
                      >
                        <b-form-group
                          label="NIP"
                          label-for="nip"
                        >
                          <b-form-input
                            id="nip"
                            v-model="contractorData.Nip"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- NIP -->

                    <!-- REGON-->
                    <b-col
                      md="6"
                      xl="3"
                    >

                      <validation-provider
                        #default="validationContext"
                        name="REGON"
                        rules="required"
                      >
                        <b-form-group
                          label="REGON"
                          label-for="regon"
                        >
                          <b-form-input
                            id="regon"
                            v-model="contractorData.Regon"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- REGON -->

                    <!-- nazwa firmy -->
                    <b-col
                      md="6"
                      xl="3"
                    >

                      <validation-provider
                        #default="validationContext"
                        name="Nazwa firmy"
                        rules="required"
                      >
                        <b-form-group
                          label="Nazwa firmy"
                          label-for="company-name"
                        >
                          <b-form-input
                            id="company-name"
                            v-model="contractorData.Name"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- nazwa firmy -->

                  </b-row>

                  <b-row>
                    <!-- ulica -->
                    <b-col
                      md="6"
                      xl="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Ulica"
                        rules="required"
                      >
                        <b-form-group
                          label="Ulica"
                          label-for="street"
                        >
                          <b-form-input
                            id="street"
                            v-model="contractorData.Street"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- ulica -->

                    <!-- kod pocztowy -->
                    <b-col
                      md="6"
                      xl="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Kod pocztowy"
                        rules="required"
                      >
                        <b-form-group
                          label="Kod pocztowy"
                          label-for="zip-code"
                        >
                          <b-form-input
                            id="zip-code"
                            v-model="contractorData.ZipCode"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- kod pocztowy -->

                    <!-- miejscowość -->
                    <b-col
                      md="6"
                      xl="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Miejscowość"
                        rules="required"
                      >
                        <b-form-group
                          label="Miejscowość"
                          label-for="city"
                        >
                          <b-form-input
                            id="city"
                            v-model="contractorData.City"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- miejscowość -->

                    <!--  Województwo -->
                    <b-col
                      md="6"
                      xl="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Województwo"
                        rules="required"
                      >
                        <b-form-group
                          label="Województwo"
                          label-for="province"
                        >
                          <b-form-input
                            id="province"
                            v-model="contractorData.Province"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  <!--  Państwo -->
                  </b-row>
                  <b-row>
                    <b-col
                      md="6"
                      xl="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Powiat"
                        rules="required"
                      >
                        <b-form-group
                          label="Powiat"
                          label-for="district"
                        >
                          <b-form-input
                            id="district"
                            v-model="contractorData.District"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="6"
                      xl="3"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Gmina"
                        rules="required"
                      >
                        <b-form-group
                          label="Gmina"
                          label-for="community"
                        >
                          <b-form-input
                            id="community"
                            v-model="contractorData.Community"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      md="6"
                      xl="3"
                    >
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Zatwierdź
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-text>
          </b-tab>

<!--          <b-tab-->
<!--            title="Dodatkowe informacje"-->
<!--            class="pt-1"-->
<!--          >-->
<!--            <b-card-text>-->

<!--              <h6 class="mb-2">-->
<!--                Adres do korespondencji (jeżeli jest inny niż dane firmowe)-->
<!--              </h6>-->

<!--              <b-row>-->
<!--                &lt;!&ndash; ulica &ndash;&gt;-->
<!--                <b-col-->
<!--                  md="6"-->
<!--                  xl="3"-->
<!--                >-->

<!--                  <b-form-group-->
<!--                    label="Ulica"-->
<!--                    label-for="company-name"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      id="company-name"-->
<!--                      value="Poznańska 23"-->
<!--                    />-->
<!--                  </b-form-group>-->
<!--                </b-col>-->
<!--                &lt;!&ndash; ulica &ndash;&gt;-->

<!--                &lt;!&ndash; kod pocztowy &ndash;&gt;-->
<!--                <b-col-->
<!--                  md="6"-->
<!--                  xl="3"-->
<!--                >-->

<!--                  <b-form-group-->
<!--                    label="Kod pocztowy"-->
<!--                    label-for="zip-code"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      id="zip-code"-->
<!--                      value="97-854"-->
<!--                    />-->
<!--                  </b-form-group>-->
<!--                </b-col>-->
<!--                &lt;!&ndash; kod pocztowy &ndash;&gt;-->

<!--              </b-row>-->

<!--              <b-row>-->

<!--                &lt;!&ndash; miejscowość &ndash;&gt;-->
<!--                <b-col-->
<!--                  md="6"-->
<!--                  xl="3"-->
<!--                >-->

<!--                  <b-form-group-->
<!--                    label="Miejscowość"-->
<!--                    label-for="company-name"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      id="company-name"-->
<!--                      value="Poznań"-->
<!--                    />-->
<!--                  </b-form-group>-->
<!--                </b-col>-->
<!--                &lt;!&ndash; miejscowość &ndash;&gt;-->

<!--                &lt;!&ndash;  Państwo &ndash;&gt;-->
<!--                <b-col-->
<!--                  md="6"-->
<!--                  xl="3"-->
<!--                >-->

<!--                  <b-form-group-->
<!--                    label="Państwo"-->
<!--                    label-for="company-name"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      id="company-name"-->
<!--                      value="Polska"-->
<!--                    />-->
<!--                  </b-form-group>-->
<!--                </b-col>-->
<!--              &lt;!&ndash;  Państwo &ndash;&gt;-->
<!--              </b-row>-->

<!--              <h6 class="mb-2 mt-2">-->
<!--                Dane kontaktowe-->
<!--              </h6>-->

<!--              <b-form-->
<!--                ref="form"-->

<!--                class="repeater-form"-->
<!--                @submit.prevent="repeateAgain"-->
<!--              >-->

<!--                &lt;!&ndash; Row Loop &ndash;&gt;-->
<!--                <b-row-->
<!--                  v-for="(item, index) in items"-->
<!--                  :id="item.id"-->
<!--                  :key="item.id"-->
<!--                  ref="row"-->
<!--                >-->

<!--                  &lt;!&ndash; Telefon &ndash;&gt;-->
<!--                  <b-col md="3">-->
<!--                    <b-form-group-->
<!--                      label="Telefon"-->
<!--                      label-for="item-name"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                        id="item-name"-->
<!--                        type="text"-->
<!--                        placeholder="+48 546 884"-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->

<!--                  &lt;!&ndash; Email &ndash;&gt;-->
<!--                  <b-col md="3">-->
<!--                    <b-form-group-->
<!--                      label="email"-->
<!--                      label-for="cost"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                        id="cost"-->
<!--                        type="email"-->
<!--                        placeholder="email@gmai.com"-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->

<!--                  &lt;!&ndash; Remove Button &ndash;&gt;-->
<!--                  <b-col-->
<!--                    lg="1"-->
<!--                    md="1"-->
<!--                    class="mb-50"-->
<!--                  >-->
<!--                    <b-button-->
<!--                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"-->
<!--                      variant="outline-danger"-->
<!--                      class="mt-0 mt-md-2"-->
<!--                      @click="removeItem(index)"-->
<!--                    >-->
<!--                      <feather-icon-->
<!--                        icon="XIcon"-->
<!--                        class="mr-25"-->
<!--                      />-->
<!--                      <span>Usuń</span>-->
<!--                    </b-button>-->
<!--                  </b-col>-->

<!--                  &lt;!&ndash; Checkbox&ndash;&gt;-->
<!--                  <b-col-->
<!--                    md="4"-->
<!--                    class="d-flex align-items-center"-->
<!--                  >-->
<!--                    <b-form-checkbox-->
<!--                      value="A"-->
<!--                    >-->
<!--                      Użyj tych danych w celu windykacji-->
<!--                    </b-form-checkbox>-->

<!--                  </b-col>-->

<!--                </b-row>-->

<!--                <b-button-->
<!--                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
<!--                  variant="primary"-->
<!--                  @click="repeateAgain"-->
<!--                >-->
<!--                  <feather-icon-->
<!--                    icon="PlusIcon"-->
<!--                    class="mr-25"-->
<!--                  />-->
<!--                  <span>Add New</span>-->
<!--                </b-button>-->

<!--              </b-form>-->

<!--              <h6 class="mb-2 mt-3">-->
<!--                Pozostałe dane-->
<!--              </h6>-->

<!--              <b-row>-->
<!--                &lt;!&ndash; strona www &ndash;&gt;-->
<!--                <b-col-->
<!--                  md="6"-->
<!--                  xl="3"-->
<!--                >-->

<!--                  <b-form-group-->
<!--                    label="Strona www"-->
<!--                    label-for="www"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      id="company-name"-->
<!--                      value="www.google.pl"-->
<!--                    />-->
<!--                  </b-form-group>-->
<!--                </b-col>-->
<!--                &lt;!&ndash; ulica &ndash;&gt;-->

<!--                &lt;!&ndash;Profil linkedin &ndash;&gt;-->
<!--                <b-col-->
<!--                  md="6"-->
<!--                  xl="3"-->
<!--                >-->

<!--                  <b-form-group-->
<!--                    label="Profil linkedin"-->
<!--                    label-for="company-name"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      id="company-name"-->
<!--                      value="linkedin.com/34343"-->
<!--                    />-->
<!--                  </b-form-group>-->
<!--                </b-col>-->
<!--                &lt;!&ndash; kod pocztowy &ndash;&gt;-->

<!--              </b-row>-->

<!--            </b-card-text>-->
<!--          </b-tab>-->

          <b-tab
            title="Historia Faktur"
            class="pt-1"
          >
            <b-card-text class="pt-3">
              <ContractorInvoicesHistory />
            </b-card-text>
          </b-tab>

        </b-tabs>
      </b-col>

    </b-row>
  </div>

</template>

<script>
import {
  BTab, BTabs, BRow, BCol, BFormInput, BFormGroup, BForm, BButton, BCardText, BFormInvalidFeedback,
} from 'bootstrap-vue'

import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import Swal from 'sweetalert2'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import ContractorInvoicesHistory from './user-history/ContractorInvoicesHistory.vue'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCardText,
    BFormInvalidFeedback,
    ContractorInvoicesHistory,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  setup(props, { emit }) {
    const blankContractorData = {
      Name: '',
      Nip: '',
      Regon: '',
      Province: '',
      District: '',
      City: '',
      Community: '',
      ZipCode: '',
      Street: '',
    }

    const contractorData = ref(JSON.parse(JSON.stringify(blankContractorData)))
    const resetcontractorData = () => {
      contractorData.value = JSON.parse(JSON.stringify(blankContractorData))
    }
    const onSubmit = () => {
      axios.put(`${process.env.VUE_APP_API_URL}Contractor/${contractorData.value.id}`, contractorData.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        emit('refetch-data')
        Swal.fire(
          'Pomyślnie zaktualizowano kontrahenta',
          '',
          'success',
        )
      }).catch(error => {
        console.log(error)
        Swal.fire('Nie udało się zaktualizować kontrahenta', '', 'error')
      })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcontractorData)

    return {
      contractorData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  data() {
    return {
      contractor: {},
    }
  },
  mounted() {
    this.fetchContractor()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async fetchContractor() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      try {
        // eslint-disable-next-line prefer-destructuring
        this.contractorData = (await axios.get(`${process.env.VUE_APP_API_URL}contractor/${this.$route.params.id}`, config)).data[0]
      } catch (e) {
        console.log(e)
      }
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
}
</script>

<style lang="scss" scoped>

.single-contractor {
    padding: 0.8rem 1rem;
    background-color: #fff;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    border-radius: 0.428rem;
}

.repeater-form {
  background-color: #fff;
  transition: .35s height;
}

</style>
